@import url('https://fonts.googleapis.com/css2?family=Google+Sans&display=swap');
.text-entry-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

header {
    background-color: #fff;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header h2 {
    margin: 0;
}

.text-input {
    width: 75%; /* Full width */
    height: 200px; /* Adjust as needed */
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none; /* Disable resizing */
}

.submit-button {
    padding: 15px 20px;
    background-color: #2a1a72;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
        font-family: 'Google Sans', sans-serif;
}

.submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    font-family: 'Google Sans', sans-serif;}

.logo {
    padding: 10px;
    background-color: #2a1a72;
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75); /* Add shadow */
}

.left-aligned {
    float: left;
}


h1 {
    font-size: 2em;
    color: #333;
    font-family: 'Google Sans', sans-serif;
}

h2 {
    font-size: 1.5em;
    color: #666;
    font-family: 'Google Sans', sans-serif;
}
/* Add more styles for h3, h4, etc. as needed */

.text-input {
    margin-top: 20px; /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
}